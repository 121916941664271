<template>
  <div
    v-if="!isLoading && !$_.isEmpty(message) && $task.isOpen"
    class="column is-12"
  >
    <div class="box has-padding-100" :class="`is-outlined-${message.theme}`">
      <component :is="message.component" class="is-size-7">
        <b-icon
          v-if="message.icon"
          slot="icon"
          :icon="message.icon"
          size="is-small"
          :class="`has-text-${message.theme}`"
        />
        <strong slot="task">{{ task.taskType }}</strong>
        <!-- Warning -->
        <strong
          v-if="message.warning"
          slot="warning"
          :class="`has-text-${message.theme}`"
          >{{ message.warning }}!</strong
        >
        <template v-if="contract">
          <!-- Contract link -->
          <router-link
            slot="subscription"
            tag="a"
            class="has-text-weight-semibold has-text-dark"
            :to="{
              name: 'adminContract',
              params: {
                contractId: contract._id
              }
            }"
          >
            <u>{{ contract.product.name }}</u>
          </router-link>
          <!-- Lasped date -->
          <span slot="lapsed">{{
            toDate($_.get(contract, "dateLapsed", contract.dateNextDue)).from()
          }}</span>
          <!-- Due date -->
          <span slot="due">{{ toDate(contract.dateNextDue).from() }}</span>
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import { enums as statuses } from "@/data/contract";

export default {
  name: "ContractInfoCard",
  inject: ["$taskProvider"],
  props: {
    siteId: {
      type: String,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statuses,
      isLoading: true,
      contract: null,
      quota: null
    };
  },
  computed: {
    $task() {
      return this.$taskProvider();
    },
    switchKey() {
      const parts = [];
      parts.push(!this.task.creditCost ? "FREE_TASK" : null);
      parts.push(this.task.consumedPaidCredits ? "PAID_TASK" : null);
      parts.push(this.task.consumedPlanCredits ? "PLAN_TASK" : null);
      parts.push(
        (this.$_.findKey(this.statuses, status => {
          return this.$_.get(this.contract, "status") === status;
        }) || "NO") + "_SUB"
      );
      parts.push(
        this.quota && this.quota.planTaskCredits >= 100
          ? "UNLIMITED_CREDITS"
          : null
      );
      return this.$_.compact(parts).join("|");
    },
    message() {
      switch (this.switchKey) {
        case "FREE_TASK|NO_SUB":
          return {
            theme: "danger",
            icon: "ban",
            warning: "STOP – Credit required",
            component: {
              template:
                "<p><slot name=icon /> <slot name=warning /> This task was raised free of charge. As this site has no subscription, any work requests should be deferred until the site has available credit.</p>"
            }
          };
        case "FREE_TASK|LAPSED_SUB":
          return {
            theme: "danger",
            icon: "ban",
            warning: "STOP – Credit required",
            component: {
              template:
                "<p><slot name=icon /> <slot name=warning /> This task was raised free of charge. As this site's <slot name=subscription /> lapsed <slot name=lapsed />, any work requests should be deferred until the site has available credit.</p>"
            }
          };
        case "FREE_TASK|OVERDUE_SUB":
          return {
            theme: "danger",
            icon: "ban",
            warning: "STOP – Credit required",
            component: {
              template:
                "<p><slot name=icon /> <slot name=warning /> This task was raised free of charge. As this site's <slot name=subscription /> went overdue <slot name=due />, any work requests should be deferred until the site has available credit.</p>"
            }
          };
        case "FREE_TASK|ACTIVE_SUB":
          return {
            theme: "danger",
            icon: "exclamation-triangle",
            warning: "Discretion advised",
            component: {
              template:
                "<p><slot name=icon /> <slot name=warning /> This task was raised free of charge, inclusive of this site's <slot name=subscription /> which renews <slot name=due />. Please use your discretion to ensure requested work falls within the scope of a '<slot name=task />' task. If it does not, the client should be asked to open a new task.</p>"
            }
          };
        case "FREE_TASK|ACTIVE_SUB|UNLIMITED_CREDITS":
          return {
            theme: "success",
            icon: "check-circle",
            component: {
              template:
                "<p><slot name=icon /> This task was raised free of charge, inclusive of this site's <slot name=subscription /> which renews <slot name=due />.</p>"
            }
          };
        case "FREE_TASK|CANCELLED_SUB":
          return {
            theme: "warning",
            icon: "exclamation-triangle",
            warning: "Discretion advised",
            component: {
              template:
                "<p><slot name=icon /> <slot name=warning /> This task was raised free of charge, inclusive of this site's <slot name=subscription /> which expires <slot name=due />. Please use your discretion to ensure requested work falls within the scope of a '<slot name=task />' task. If it does not, the client should be asked to open a new task</p>"
            }
          };
        case "FREE_TASK|CANCELLED_SUB|UNLIMITED_CREDITS":
          return {
            theme: "success",
            icon: "check-circle",
            component: {
              template:
                "<p><slot name=icon /> This task was raised free of charge, inclusive of this site's <slot name=subscription /> which expires <slot name=due />.</p>"
            }
          };
        case "PAID_TASK|NO_SUB":
          return {
            theme: "success",
            icon: "check-circle",
            component: {
              template:
                "<p><slot name=icon /> This task consumed a paid/one-time credit. This site has no subscription.</p>"
            }
          };
        case "PAID_TASK|ACTIVE_SUB":
        case "PAID_TASK|ACTIVE_SUB|UNLIMITED_CREDITS":
          return {
            theme: "success",
            icon: "check-circle",
            component: {
              template:
                "<p><slot name=icon /> This task consumed a paid/one-time credit. This site also has an active <slot name=subscription /> which renews <slot name=due />.</p>"
            }
          };
        case "PAID_TASK|CANCELLED_SUB":
        case "PAID_TASK|CANCELLED_SUB|UNLIMITED_CREDITS":
          return {
            theme: "success",
            icon: "check-circle",
            component: {
              template:
                "<p><slot name=icon /> This task consumed a paid/one-time credit. This site also has a cancelled <slot name=subscription /> which expires <slot name=due />.</p>"
            }
          };
        case "PAID_TASK|OVERDUE_SUB":
          return {
            theme: "success",
            icon: "check-circle",
            component: {
              template:
                "<p><slot name=icon /> This task consumed a paid/one-time credit. This site also has an overdue <slot name=subscription /> which was due <slot name=due />.</p>"
            }
          };
        case "PAID_TASK|LAPSED_SUB":
          return {
            theme: "success",
            icon: "check-circle",
            component: {
              template:
                "<p><slot name=icon /> This task consumed a paid/one-time credit. This site previously had a <slot name=subscription /> which lapsed <slot name=lapsed />.</p>"
            }
          };
        case "PLAN_TASK|ACTIVE_SUB":
        case "PLAN_TASK|ACTIVE_SUB|UNLIMITED_CREDITS":
          return {
            theme: "success",
            icon: "check-circle",
            component: {
              template:
                "<p><slot name=icon /> This task consumed a plan credit from its site's <slot name=subscription />, which renews <slot name=due />.</p>"
            }
          };
        case "PLAN_TASK|CANCELLED_SUB":
        case "PLAN_TASK|CANCELLED_SUB|UNLIMITED_CREDITS":
          return {
            theme: "success",
            icon: "check-circle",
            component: {
              template:
                "<p><slot name=icon /> This task consumed a plan credit from its site's <slot name=subscription />, which expires <slot name=due />.</p>"
            }
          };
        case "PLAN_TASK|LAPSED_SUB":
        case "PLAN_TASK|LAPSED_SUB|UNLIMITED_CREDITS":
          return {
            theme: "danger",
            icon: "exclamation-triangle",
            warning: "Discretion advised",
            component: {
              template:
                "<p><slot name=icon /> <slot name=warning /> This task consumed a plan credit from its site's <slot name=subscription />, which has since lapsed (<slot name=due />). Knowing this, please ensure this task is NOT being used to request additional work free-of-charge.</p>"
            }
          };
        case "PLAN_TASK|OVERDUE_SUB":
        case "PLAN_TASK|OVERDUE_SUB|UNLIMITED_CREDITS":
          return {
            theme: "danger",
            icon: "exclamation-triangle",
            warning: "Discretion advised",
            component: {
              template:
                "<p><slot name=icon /> <slot name=warning /> This task consumed a plan credit from its site's <slot name=subscription />, which has since gone overdue (<slot name=due />). Knowing this, please ensure this task is NOT being used to request additional work free-of-charge.</p>"
            }
          };
        default:
          return {};
      }
    }
  },
  created() {
    this.getSiteSubscription();
  },
  methods: {
    toDate(dateObject) {
      return this.$moment.unix(dateObject._seconds);
    },
    getSiteSubscription() {
      this.isLoading = true;
      return this.$store
        .dispatch("billing/getSiteSubscription", this.siteId)
        .then(subscription => {
          this.$set(this, "contract", subscription.contract);
          this.$set(this, "quota", subscription.quota);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
